import { gql, useQuery } from '@apollo/client';
import { RTElementProp, RTFileElement, RTSerializer, fromSlateNodes } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';
import styled from 'styled-components';

import { useDownloadFile } from '../../../../../hooks/useDownloadFile';
import { ConditionalWrapper } from '../../../../lib/ConditionalWrapper';
import Tooltip from '../../../../Tooltip';
import FilePill from '../../../../ui/Pills/FilePill';
import { IRTElementProps } from '../../../IRTElementProps';
import { ResolverProgressOverlay } from '../../common/ResolverStatusOverlay';
import { IUseResolverStatusResult, useResolverStatus } from '../../common/useResolverStatus';
import VoidInlineElement from '../../common/VoidInlineElement';

import {
  BATCHED__FileElement_FileQuery,
  BATCHED__FileElement_FileQueryVariables,
} from './___generated___/FileElement.types';

const _Link = styled.a`
  display: inline-block;
  max-width: 100%;
  position: relative;
`;

const _FilePill = styled(FilePill)`
  position: relative; /* to support upload progress bar overlay */
`;

export interface IBaseFileElementProps extends IRTElementProps<RTFileElement> {
  contentType?: string;
  extension?: string;
  name: string;
  resolverStatus: IUseResolverStatusResult;
  selected?: boolean;
  src?: string;
}

export function BaseFileElement({
  contentType,
  extension,
  name,
  resolverStatus,
  selected,
  src,
  ...props
}: IBaseFileElementProps) {
  const downloadFile = useDownloadFile();

  return (
    <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
      <_Link
        href="#"
        onClick={(e) => {
          e.preventDefault();

          if (props.editable || !src) {
            return;
          }

          downloadFile({ url: src, filename: name });
        }}
      >
        <ConditionalWrapper
          condition={!!resolverStatus.status?.error}
          wrapper={({ children }) => (
            <Tooltip title={resolverStatus.status?.error?.message}>
              <span>{children}</span>
            </Tooltip>
          )}
        >
          <_FilePill
            contentType={contentType}
            extension={extension}
            hasError={!!resolverStatus.status?.error}
            selected={selected}
            title={name}
          >
            <ResolverProgressOverlay status={resolverStatus.status} />
            {name}
          </_FilePill>
        </ConditionalWrapper>
      </_Link>
    </VoidInlineElement>
  );
}

///////////////////////////

const fragment = gql`
  fragment CF_FileElement on File {
    id
    contentType
    name
    url
  }
`;

const FileQuery = gql`
  ${fragment}
  # Batched query
  query BATCHED__FileElement_FileQuery($fileId: ID!) {
    file(id: $fileId) {
      ...CF_FileElement
    }
  }
`;

export default function FileElement(props: IRTElementProps<RTFileElement>) {
  const selected = useSelected();

  const resolverStatus = useResolverStatus(props);

  const fileQuery = useQuery<BATCHED__FileElement_FileQuery, BATCHED__FileElement_FileQueryVariables>(FileQuery, {
    variables: { fileId: props.element[RTElementProp.FILE__FILE_ID] ?? '' },
    skip: !props.element[RTElementProp.FILE__FILE_ID],
  });

  const contentType = resolverStatus.status?.data?.file?.type ?? fileQuery.data?.file?.contentType ?? undefined;
  const name =
    resolverStatus.status?.data?.file?.name ??
    fileQuery.data?.file?.name ??
    RTSerializer.toPlaintext(fromSlateNodes(props.element.children)); // We save a copy of original file name in the element's child
  const src = resolverStatus.previewUrl ?? fileQuery.data?.file?.url ?? undefined;

  return (
    <BaseFileElement
      {...props}
      contentType={contentType}
      extension={name?.split('.').pop()}
      name={name}
      resolverStatus={resolverStatus}
      selected={selected}
      src={src}
    />
  );
}

FileElement.fragment = fragment;
