import { gql, useLazyQuery } from '@apollo/client';
import * as React from 'react';

import { useDownloadImageQuery, useDownloadImageQueryVariables } from './___generated___/useDownloadImage.types';
import { useDownloadFile } from './useDownloadFile';

const DownloadImageQuery = gql`
  query useDownloadImageQuery($id: ID!) {
    image(id: $id) {
      id
      downloadUrl
      name
    }
  }
`;

export interface IUseDownloadImageProps {
  id?: string | null;
}

export function useDownloadImage({ id }: IUseDownloadImageProps) {
  const downloadFile = useDownloadFile();
  const [queryData] = useLazyQuery<useDownloadImageQuery, useDownloadImageQueryVariables>(DownloadImageQuery);

  const downloadImage = React.useCallback(async () => {
    if (!id) {
      return;
    }
    const { data } = await queryData({ variables: { id } });
    if (!data?.image?.downloadUrl) {
      return;
    }
    downloadFile({ url: data.image.downloadUrl, filename: data.image.name });
  }, [downloadFile, id, queryData]);

  return downloadImage;
}
