import { gql, useLazyQuery } from '@apollo/client';
import * as React from 'react';

import { useDownloadMediaQuery, useDownloadMediaQueryVariables } from './___generated___/useDownloadMedia.types';
import { useDownloadFile } from './useDownloadFile';

const DownloadMediaQuery = gql`
  query useDownloadMediaQuery($id: ID!) {
    media(id: $id) {
      id
      downloadUrl
      name
    }
  }
`;

export interface IUseDownloadMediaProps {
  id?: string | null;
}

export function useDownloadMedia({ id }: IUseDownloadMediaProps) {
  const downloadFile = useDownloadFile();
  const [queryData] = useLazyQuery<useDownloadMediaQuery, useDownloadMediaQueryVariables>(DownloadMediaQuery);

  const downloadMedia = React.useCallback(async () => {
    if (!id) {
      return;
    }
    const { data } = await queryData({ variables: { id } });
    if (!data?.media?.downloadUrl) {
      return;
    }
    downloadFile({ url: data.media.downloadUrl, filename: data.media.name });
  }, [downloadFile, id, queryData]);

  return downloadMedia;
}
