import * as React from 'react';

export interface IUseDownloadFileProps {
  url: string;
  filename?: string;
}

export function useDownloadFile() {
  const downloadFile = React.useCallback(({ url, filename }: IUseDownloadFileProps) => {
    const a = document.createElement('a');
    a.download = filename ?? new URL(url).pathname.split('/').pop() ?? 'emdash download';
    a.href = url;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, []);

  return downloadFile;
}
